import React, { useEffect, useState } from 'react';
import { Router, Switch, Route } from "react-router-dom";
import history from "../Utils/History";
import { renderRoutes } from 'react-router-config';
import routes from "./routes";
import * as FirebaseFunctions from "../Utils/Firebase";

import {
  IconButton,
  Snackbar,
} from '@material-ui/core';

import {
  Close as CloseIcon,
  ErrorOutline
} from '@material-ui/icons';

import {
  AppHeaderBar
} from '../Components';

import './styles.sass';

function Routing(): React.ReactElement {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<false|string>(false)

  // Check sign in state
  FirebaseFunctions.onAuthStateChanged((user: any) => {
    const _isLoggedIn = user !== null;
    if(isLoggedIn!==_isLoggedIn)
      setIsLoggedIn(_isLoggedIn);
  });

  useEffect(()=>{

    if((history.location.pathname !== "/") && !isLoggedIn){
      history.replace("/");
    }

    if((history.location.pathname === "/") && isLoggedIn){
      history.replace("/upload");
    }
      
  },[isLoggedIn]);

  return (
    <Router history={history}>
      <Route
        render={({ location }): React.ReactElement => (
          <div className="app-wrapper">
            <AppHeaderBar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            <Switch location={location}>
              {renderRoutes(routes, {
                isLoggedIn: isLoggedIn,
                setGlobalErrorState: setErrorState
              })}
            </Switch>
            <Snackbar
              className="snackbar error"
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={errorState!==false}
              autoHideDuration={5000}
              onClose={()=>setErrorState(false)}
              message={<span>
                  <ErrorOutline/>
                  <span>{errorState}</span>
                </span>}
              action={
                <React.Fragment>
                  <IconButton size="small" aria-label="close" color="inherit" onClick={()=>setErrorState(false)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
              ContentProps={{
                className:"snackbar error"
              }}
            />
          </div>
        )}
      />
    </Router>
  );
}

export default Routing;