import React, { useState } from 'react';

import { 
    AppBar,
    Menu,
    MenuItem,
    Toolbar,
    IconButton
 } from '@material-ui/core';

 import {
  MoreVert as MoreVertIcon
 } from '@material-ui/icons';

import * as FirebaseFunctions from '../../Utils/Firebase';
import './styles.sass';

function AppHeaderBar(props: any): React.ReactElement{
  const logo = require('Images/pixeo-logo.svg');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    FirebaseFunctions.signOut()
  }

  const ITEM_HEIGHT = 48;

  return (
    <div>
      <AppBar position="static" color="default">
        <Toolbar>
          <div  style={{  display: 'flex', alignItems: 'center', marginRight: 'auto', marginLeft: '-25px' }}>
            <img src={logo} style={{ height: '30px' }} alt="Pixeo" />
          </div>
          {
            props.isLoggedIn && (<div>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="long-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: '20ch',
                    },
                  }}
                >
                  <MenuItem key='Sign out' onClick={handleSignOut}>
                    Sign Out
                  </MenuItem>
                </Menu>
              </div>
            )
          }
        </Toolbar>
      </AppBar>
    </div>
  );
}
  
export default AppHeaderBar;