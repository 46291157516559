import React, { Component } from 'react';
import {
    Delete as DeleteIcon
} from '@material-ui/icons';

import './styles.sass';

import clsx from 'clsx';

const ERROR = {
    FILE_NOT_FOUND: 'FILE_NOT_FOUND',
    NOT_SUPPORTED_EXTENSION: 'NOT_SUPPORTED_EXTENSION',
    FILESIZE_TOO_LARGE: 'FILESIZE_TOO_LARGE'
};

interface propTypes {
    style: Object;
    fileContainerStyle: Object;
    className: string;
    onChange: (file: File | undefined, picture: string | undefined)=>void;
    onDelete: ()=>void;
    onError: (error: fileErrorType)=>void;
    buttonClassName: string;
    buttonStyles: Object;
    buttonType: "button" | "submit" | "reset" | undefined;
    accept: string;
    name: string;
    buttonText: React.ReactNode;
    label: string;
    labelStyles: Object;
    labelClass: string;
    imgExtension: string[];
    maxFileSize: number;
    errorClass: string;
    errorStyle: Object;
};

interface stateTypes {
    picture?: string ;
    file?: File ;
    isFileDropped: boolean
};

interface fileErrorType {
    name: string,
    type?: string
};

class ImageUploadComponent extends Component<propTypes, stateTypes> {
    inputElement : React.RefObject<HTMLInputElement>;

    static defaultProps: propTypes = {
        className: '',
        fileContainerStyle: {},
        buttonClassName: "",
        buttonStyles: {},
        accept: "image/*",
        name: "",
        buttonText: "Choose images",
        buttonType: "button",
        label: "Max file size: 5mb, accepted: jpg|gif|png",
        labelStyles: {},
        labelClass: "",
        imgExtension: ['.jpg', '.jpeg', '.gif', '.png'],
        maxFileSize: 5242880,
        errorClass: "",
        style: {},
        errorStyle: {},
        onChange: () => {},
        onDelete: () => {},
        onError: () => {}
    };

    constructor(props: propTypes) {
        super(props);
        this.state = {
            isFileDropped: false
        };
        this.inputElement = React.createRef();
        this.onDropFile = this.onDropFile.bind(this);
        this.onUploadClick = this.onUploadClick.bind(this);
        this.triggerFileUpload = this.triggerFileUpload.bind(this);
    }

    /*
        Check file extension (onDropFile)
    */
    hasExtension(fileName: string) {
        const pattern = '(' + this.props.imgExtension.join('|').replace(/\./g, '\\.') + ')$';
        return new RegExp(pattern, 'i').test(fileName);
    }

    /*
        Handle file validation
    */
    onDropFile(event: React.ChangeEvent<HTMLInputElement>) {
        let hasErrors: boolean = false;
        const files = event.currentTarget.files;

        if(files?.length !== 1){
            this.props.onError({
                name: '-',
                type: ERROR.FILE_NOT_FOUND
            })
            return;            
        }
        
        const file = files[0];

        // Check for file extension
        if (!this.hasExtension(file.name)) {
            this.props.onError({
                name: file.name,
                type: ERROR.NOT_SUPPORTED_EXTENSION
            });
            hasErrors = true;
        }

        // Check for file size
        if(file.size > this.props.maxFileSize) {
            this.props.onError({
                name: file.name,
                type: ERROR.FILESIZE_TOO_LARGE
            })
            hasErrors = true
        }

        if(hasErrors) return;
        
        this.readFile(file).then(fileData => {
            this.setState({picture: fileData.dataURL, file: fileData.file, isFileDropped: true},()=>{
                this.props.onChange(this.state.file, this.state.picture);
            });
        });
    }

    onUploadClick(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        event.currentTarget.value = '';
    }

    /*
        Read a file and return a promise that when resolved gives the file itself and the data URL
    */
    readFile(file: File): Promise<{ file: File; dataURL: string | undefined; }> {
        return new Promise((resolve, reject) => {
        const reader = new FileReader();

        // Read the image via FileReader API and save image result in state.
        reader.onload =  function (e) {
            // Add the file name to the data URL
            let result = this.result;
            let dataURL: string|undefined;
            if(typeof result == 'string')
                dataURL = result.replace(";base64", `;name=${file.name};base64`);
            resolve({file, dataURL});
        };

        reader.readAsDataURL(file);
        });
    }

    /*
        Remove the image from state
    */
    removeImage() {
        this.setState({picture: undefined, file: undefined, isFileDropped: false}, this.props.onDelete);
    }

    /*
        Render preview images
    */
    renderPreview(expanded: boolean) {
        return (
            <div className={clsx("uploadPicturesWrapper", !expanded && "hide")}>
                {this.renderPreviewPicture()}
            </div>
        );
    }

    renderPreviewPicture() {
        const {picture} = this.state;
        return (
            <div className="uploadPictureContainer">
                <div className="deleteImage"><DeleteIcon onClick={() => this.removeImage()} /></div>
                <img src={picture} className="uploadPicture" alt="preview"/>
            </div>
        );
    }

    renderUploadButton(expanded: boolean) {
        return (
            <div className={clsx("chooseFileButtonWrapper", !expanded && "hide")}>
                <button
                    type={this.props.buttonType}
                    className={"chooseFileButton " + this.props.buttonClassName}
                    style={this.props.buttonStyles}
                    onClick={this.triggerFileUpload}
                >
                    {this.props.buttonText}
                </button>
                <input
                    type="file"
                    ref={this.inputElement}
                    name={this.props.name}
                    onChange={this.onDropFile}
                    onClick={this.onUploadClick}
                    accept={this.props.accept}
                />
            </div>
        );
    }

    /*
        On button click, trigger input file to open
    */
    triggerFileUpload() {
        this.inputElement.current?.click();
    }

    render() {
        const {
            isFileDropped
        } = this.state;
        return (
            <div className="fileUploader">
                <div className="fileContainer">
                    { this.renderUploadButton(!isFileDropped) }
                    { this.renderPreview(isFileDropped) }
                </div>
            </div>
        )
    }
}




export default ImageUploadComponent;