import { lazy } from 'react';
import AsyncComponentLoader from '../Components/AsyncComponentLoader';

export default [
  {
    title: 'Upload',
    path: '/upload',
    component: AsyncComponentLoader(lazy(() => import('../Pages/Upload/index'))),
  },
  {
    title: 'Home',
    path: '/',
    component: AsyncComponentLoader(lazy(() => import('../Pages/SignIn/index'))),
  },
  {
    component: AsyncComponentLoader(lazy(() => import('../Pages/NotFound/index'))),
  }
];