import * as Firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

export const isLoggedIn = () => {
    const user = Firebase.auth().currentUser;
    if(user!==null)
      return true;
    else
      return false;
}

export const signOut = () => {
    return Firebase.auth().signOut();
}

export const handleSignIn = (email: string, password: string) => {
    return Firebase.auth().signInWithEmailAndPassword(email, password);
}

export const handleGoogleSignIn = () => {
    var provider = new Firebase.auth.GoogleAuthProvider();
    return Firebase.auth().signInWithPopup(provider);
}

export const handleAppleSignIn = () => {
    const provider = new Firebase.auth.OAuthProvider('apple.com')
    return Firebase.auth().signInWithPopup(provider)
}

export const sendPasswordResetEmail = (email: string)=>{
    return Firebase.auth().sendPasswordResetEmail(email);
}

export const getCurrentUser = () => {
    return Firebase.auth().currentUser;
}

export const onAuthStateChanged = (callback: Firebase.Observer<any, Error> | ((a: Firebase.User | null) => any), error?: ((a: Firebase.auth.Error) => any) | undefined, completed?: Firebase.Unsubscribe | undefined): Firebase.Unsubscribe => {
    return Firebase.auth().onAuthStateChanged(callback);
}

export const getStorage = () => {
    return Firebase.storage();
}

export const getDatabase = () => {
    return Firebase.database();
}

export const getDatabaseClass = () => {
    return Firebase.database;
}
