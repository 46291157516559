if (!process.env.REACT_APP_MODE || (process.env.REACT_APP_MODE !== 'dev' && process.env.REACT_APP_MODE !== 'prod')) {
  console.error('The environment variable REACT_APP_MODE is required for this app to work. See README.md for details.');
}

var config = {
  cloudFunctionsURL: `https://us-central1-cape-split-${process.env.REACT_APP_MODE}.cloudfunctions.net`,
  appConfig: {}
};

if (process.env.REACT_APP_MODE === 'dev') {
  config.appConfig = {
    apiKey: 'AIzaSyB6URXT4eTcmeIjOp7MwlEIFUf_sDrUTE8',
    authDomain: 'cape-split-dev.firebaseapp.com',
    databaseURL: 'https://cape-split-dev.firebaseio.com',
    projectId: 'cape-split-dev',
    storageBucket: 'cape-split-dev.appspot.com',
    messagingSenderId: '847214805428'
  };
} else if (process.env.REACT_APP_MODE === 'prod') {
  config.appConfig = {
    apiKey: 'AIzaSyCOn1C3xznYQgV9E61UKJiALwDrn6iWkNE',
    authDomain: 'cape-split-prod.firebaseapp.com',
    databaseURL: 'https://cape-split-prod.firebaseio.com',
    projectId: 'cape-split-prod',
    storageBucket: 'cape-split-prod.appspot.com',
    messagingSenderId: '30116820110'
  };
}

export default config;